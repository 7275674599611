@media only screen and (max-width: 450px) {
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.App-stage {
  text-align: center;
  flex-shrink: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 760px;
  min-width: 320px;
}

.App-header {
  background-color: #282c34;
  width: 90%;
  height: 80px;
  margin:10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #474747;;
}

.App-footer {
  width: 90%;
  min-height: 60px;
  margin:10px;
  flex-shrink: 0;
  flex-grow: 1;
  display: grid;
  grid-template-areas: "widget copyright";
  grid-template-rows: 1fr;
  grid-template-columns: 0px minmax(0, 1fr);
  background-color: #282c34;
  text-align: bottom;
  font-size: calc(0.6em);
  color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #474747;
}
.App-footer a:link{
  color: #61dafb;
}
.App-footer a:visited{
  color: #a9d8e6;
}

.footer-attribution{
  height: 16px;
  width: 100%;
  display: inlin;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.footer-changelog {
  display: inline-block; 
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  padding: 0px;
  font-size: 10px;
  text-decoration: underline;
  color: #61dafb;
}
}
