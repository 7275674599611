@media (min-width: 451px) {
.cookie-consent-bar{
  background-color: #282c34;
  width: 90%;
  height: 20%;
  position: fixed; z-index: 10;
  bottom: 5px;
  margin:10px;
  padding:10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-style: groove;
  border-width: 4px;
  border-color:rgb(196, 112, 3);
}

.cookie-consent-text{
  font-size: calc(6px + 2vmin);
  color: white;
  font-weight: bold;
}

.cookie-consent-button {
  box-shadow:inset 0px 1px 3px 0px #91b8b3;
  background:linear-gradient(to bottom, rgba(65, 43, 22, 0.9) 5%, rgba(105, 105, 105, 0.9) 100%);
  border-radius:6px;
  border:1px solid #dd4205;
  display:inline-block;
  margin-top:3vmin;
  cursor:pointer;
  color: #ffffff;
  font-family:Arial;
  font-size:calc(2px + 2vmin);
  font-weight:bold;
  padding:10px 10px;
  text-decoration:none;
  border-radius:6px;
}

.cookie-consent-button:hover {
  background:linear-gradient(to bottom, #494744 5%, #3a2404 100%);
  background-color:#3a2404;
  color: #ffffff;
  text-shadow:0px -1px 0px rgb(60, 60, 60);
}

.cookie-consent-button:active {
  position:relative;
  top:1px;
}
}