@media only screen and (max-width: 450px) {
.stage-bottom-outer {
  width: 90%;
  min-width: 290px;
  height: calc(100vh - 360px);
  min-height: 720px;
  flex: 2 0 auto;
  position: relative;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  background-image: linear-gradient(
    rgba(235, 235, 255, .70),
    rgba(255, 255, 255, 0.6)
  );  
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border-style: groove;
  border-width: 3px;
  border-color:rgb(211, 209, 209);
  border-radius: 10px;
  box-shadow: 5px 5px 10px;
}

.stage-bottom-inner {
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-panel {
  width: 95%;
  height: 65%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: left;
  padding: 3px;
  margin: auto;
  overflow: hidden;
  background-image: linear-gradient(
    rgba(235, 235, 255),
    rgba(255, 255, 255, 0.9)
  );
  border-style: solid;
  border-width: 2px;
  border-color:rgb(211, 209, 209) rgb(161, 161, 161) rgb(150, 150, 150) rgb(207, 207, 207);
  border-radius: 10px;
}

.select-panel-item {
  width: 65px;
  height: 75px;
  position: relative;
  display: block;
  background-color: #3d3c3c;
  margin: 2px;
  border-style: solid;
  border-color:#bbb #888 #666 #aaa;
	border-width: 3px 4px 4px 3px;
  border-radius: 5px;
}
.select-panel-item:hover{
	border-color: #666 #aaa #bbb #888;
	border-width: 4px 3px 3px 4px;
}

.select-panel-remove-button {
  position:absolute;
  display: inline-block;                 
  bottom:0;                          
  right:0; 
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  color: #cc0303;
}
.select-panel-remove-button:hover {
  background-color: #cc8080;
  border: none;
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  color: #cc0303;
}

.select-creature-image {
  width: 55px;
  height: 65px;
  position: relative;
  background-color: #141414;
  margin: 5px;
  border-radius: 5px;
}

.view-panel {
  width: 90%;
  height: 28%;
  display: flex;
  flex-flow: column-reverse;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    rgba(135, 135, 155, .4),
    rgba(155, 155, 155, 0.6)
  );
  padding: 5px;
  margin: auto;
  border-radius: 5px;
}

.view-panel-iframe {
  width: 100%;
  height: calc(100% - 14px);
  flex: 1 0 auto;
}

.view-panel-remove-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  margin-left: auto;
  border: none;
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  color: #cc0303;
}
.view-panel-remove-button:hover {
  background-color: #cc8080;
  border: none;
  padding: 0px;
  font-size: 12px;
  font-weight: bold;
  color: #cc0303;
}
}